<template>
  <CButton style="float: right" color="dark" @click="$router.go(-1)">
    Back
  </CButton>
</template>
<script>
export default {
  name: "backToList",
  data() {
    return {};
  },
};
</script>
