<template>
  <div>
    <CRow>
      <CCol xs="12" lg="12">
        <CCard>
          <CCardHeader>
            <div class="row">
              <CCol sm="6" class="align-self-left">
                <strong>Current Plan Detail</strong>
              </CCol>
              <CCol sm="6" class="align-self-right">
                <back-to-list></back-to-list>
              </CCol>
            </div>
          </CCardHeader>
          <CCardBody>
            <CAlert :color="messageColor" closeButton :show.sync="dismissCountDown" fade>
              {{ err_msg }}
            </CAlert>

            <CForm @submit.prevent="onProfileSubmit">
              <table class="table border">
                <tr>
                  <th>{{ $lang.plan.form.industry }}</th>
                  <td>{{plan.industryId.label}}</td>
                </tr>
                <tr>
                  <th>{{ $lang.plan.form.type }}</th>
                  <td>{{plan.type.label}}</td>
                </tr>
                <tr>
                  <th>{{ $lang.plan.form.name }}</th>
                  <td>{{plan.name}}</td>
                </tr>
                <tr>
                  <th>{{ $lang.plan.form.description }}</th>
                  <td>{{plan.description}}</td>
                </tr>
                <tr>
                  <th>{{ $lang.plan.form.duration }}</th>
                  <td>{{plan.duration.label}}</td>
                </tr>
                <tr>
                  <th>{{ $lang.plan.form.no_of }}</th>
                  <td>{{plan.no_of}}</td>
                </tr>
                <tr>
                  <th>{{ $lang.plan.form.featuredetail }}</th>
                  <td>{{plan.featuredetail}}</td>
                </tr>
                <tr>
                  <th>{{ $lang.plan.form.can_join }}</th>
                  <td v-if="plan.can_join == 1">Yes</td>
                  <td v-if="plan.can_join == 0">No</td>
                </tr>
                <tr>
                  <th>{{ $lang.plan.form.is_free }}</th>
                  <td v-if="plan.is_free == 1">Yes</td>
                  <td v-if="plan.is_free == 0">No</td>
                </tr>
                <tr>
                  <th>{{ $lang.plan.form.is_auto_assign }}</th>
                  <td v-if="plan.is_auto_assign == 1">Yes</td>
                  <td v-if="plan.is_auto_assign == 0">No</td>
                </tr>
                <tr>
                  <th>{{ $lang.common.form.isActive }}</th>
                  <td v-if="plan.status == 1">Yes</td>
                  <td v-if="plan.status == 0">No</td>
                </tr>
                <tr v-if="plan.type.id==='Company'">
                  <th>{{ $lang.plan.form.no_user }}<required_span/></th>
                  <td><input type="text" :placeholder="$lang.plan.form.no_user" class="form-control" v-model="plan.no_user"/></td>
                </tr>
                <tr v-if="plan.type.id==='Company'">
                  <th>{{ $lang.plan.form.no_branch }}<required_span/></th>
                  <td><input type="text" :placeholder="$lang.plan.form.no_branch" class="form-control" v-model="plan.no_branch"/></td>
                </tr>
              </table>
              <CRow>
                <CCol md="12">
                  <h4>Menu</h4>
                  <table class="table table-bordered">
                    <tr>
                      <th>Sequence</th>
                      <th>Module</th>
                      <th>Permission</th>
                      <th>Action</th>
                    <tr>
                    <tr v-for="(bottom,index) in bottomMenuList">
                      <td>
                        {{ index + 1 }}
                      </td>
                      <td>
                        <v-select :id="bottom.id" v-model="bottom.selected"
                                  @input="changePermission(index,bottom.selected)"
                                  :options="bottomMenuOptions"></v-select>
                      </td>
                      <td>
                        <v-select :id="bottom.id" v-model="bottom.permission" :options="bottom.permissionList"
                                  multiple></v-select>
                      </td>
                      <td>
                        <button type="button" class="btn btn-success" @click="addMenu">
                          <i class="fa fa-plus"></i>
                        </button>&nbsp;
                        <!--                        <button type="button" class="btn btn-danger" @click="subMenu(bottom.id)">-->
                        <!--                          <i class="fa fa-minus"></i>-->
                        <!--                        </button>-->
                      </td>
                    </tr>
                  </table>
                </CCol>
              </CRow>

              <CRow>
                <CCol md="12">
                  <div class="form-group">
                    <label class="form__label">{{ $lang.plan.form.informToUser }}
                      <required_span/>
                    </label>
                    <textarea type="text" :placeholder="$lang.plan.form.informToUser" class="form-control"
                              v-model="plan.informToUser"/>

                    <small
                        class="error"
                        v-if="$v.plan.informToUser.$error && !$v.plan.informToUser.required"
                    >{{
                        $lang.plan.validation.required.informToUser
                      }}</small
                    >

                  </div>
                </CCol>
              </CRow>

              <CRow>
                <CCol col="2" class="text-left">
                  <CButton color="primary" :disabled="submitted" class="px-4" type="submit">
                    {{ $lang.common.general.submit }}
                  </CButton>

                </CCol>

              </CRow>
            </CForm>
          </CCardBody>
        </CCard>
      </CCol>
    </CRow>
  </div>
</template>
<script>
import Vue from "vue";
import VueForm from "vue-form";
import store from "/src/store/store.js";
import {country, industry, userPlan} from "/src/store/url.js";
import {Mixin} from "/src/mixins/index.js";
import options from "/src/validations/validations.js";
import {ServerTable} from "vue-tables-2";
import {required, requiredIf} from "vuelidate/lib/validators";
import required_span from "/src/components/layouts/general/required-span";
import {Maxlength} from "/src/store/maxlength";
import BackToList from "/src/views/backToList.vue";
import $ from "jquery";

Vue.use(ServerTable, {}, false);
Vue.use(VueForm, options);
export default {
  name: "EditUserPlanPermissions",
  mixins: [Mixin],
  components: {
    required_span,
    BackToList,
  },
  data() {
    return {
      maxV: '',
      counter: 0,
      menuCounter: 0,
      sideMenuCounter: 0,
      inputs: [],
      module: userPlan,
      exampleData: [],
      industryLists: [],
      moduleIndustry: industry,
      messageColor: "",
      submitted: false,
      SubmitBtn: "Submit",
      err_msg: "",
      alertMessage: "test",
      showAlert: false,
      dismissCountDown: 0,
      self: this,
      moduleCountry: country,
      msg: "",
      typeOptions: [
        {
          'id': 'Company',
          'label': 'Company',
        },
        {
          'id': 'Individual',
          'label': 'Individual',
        },
      ],
      durationOptions: [
        {
          'id': 'Yearly',
          'label': 'Yearly',
        },
        {
          'id': 'Monthly',
          'label': 'Monthly',
        },
        {
          'id': 'Days',
          'label': 'Days',
        },
      ],
      countryOptions: [],
      bottomMenuOptions: [],
      bottomMenuList: [{
        id: '0',
        selected: '',
        permission: [],
        permissionList: []
      }],
      // sideMenuOptions : [],
      // sideMenuList:[{
      //   id: '0',
      //   selected:'',
      //   permission:[],
      //   permissionList:[]
      // }],
      editId: '',
      planPricing: '',
      userplanPricing: '',
      plan: {
        type: "",
        name: "",
        description: "",
        duration: "",
        status: 1,
        no_user: "",
        no_branch: "",
        can_join: 0,
        is_free: 0,
        no_of: '',
        featuredetail: "",
        industryId: '',
        is_auto_assign: 0,
        informToUser:"",
      },
      maxlength: {
        name: Maxlength.plan.name,
        description: Maxlength.plan.description,
        no_user: Maxlength.plan.no_user,
        no_branch: Maxlength.plan.no_branch,
        price: Maxlength.plan.price,
        no_of: Maxlength.plan.no_of,
      },
    };
  },
  validations: {
    plan: {
      /*  type: {
          required,
        },
        name: {
          required,
        },
        description: {
          required,
        },
        duration: {
          required,
        },
        industryId: {
          required,
        },*/
      no_user: {
        requiredIf: requiredIf(function () {
          return this.plan.type.id === "Company";

        }),
      },
      no_branch: {
        requiredIf: requiredIf(function () {
          return this.plan.type.id === "Company";
        }),
      },
      informToUser: {
        required,
      },
      no_of: {
        required,
        minValue: (function (value) {
          if (value === '') {
            return true;
          }
          return 1 <= value;
        }),
        maxValue: (function (value) {
          let self = this;
          const du = self.plan.duration.id;
          if (du === "Yearly") {
            if (value <= 9999) {
              return true;
            }
            self.maxV = 9999;
            return false;
          } else if (du === "Monthly") {
            if (value <= 12) {
              return true;
            }
            self.maxV = 12;
            return false;
          } else {
            if (value <= 365) {
              return true;
            }
            self.maxV = 365;
            return false;
          }
        }),
      },
      /*featuredetail: {
        required,
      },*/
    },
  },
  mounted() {
    let self = this;
    store.commit("showLoader", false); // Loader Off
    self.dismissCountDown = 0;
    this.$root.$on("alert", (arg1, arg2) => {
    });
    if (localStorage.getItem("showAlert") !== "") {
      this.setAlert(true);
    }
    this.setAlert(false);
    localStorage.setItem("showAlert", "");
    axios.get('/countries/lists').then((response) => {
      response.data.data.map(function (value, key) {
        self.countryOptions.push({value: value.id, label: value.name});
      });
    });
    axios.get(this.listUrlApi(this.moduleIndustry)).then((response) => {
      response.data.data.map(function (value, key) {
        if (value.industryId <= 0) {
          self.industryLists.push({value: value.id, label: value.industry});
        }
      });
    });
    axios.get('/plan/permission/lists').then((response) => {
      response.data.data.map(function (value, key) {
        self.bottomMenuOptions.push({value: value.id, label: value.title});
      });
    });
    // axios.get('/plan/permission/side-menu').then((response) => {
    //   response.data.data.map(function (value, key) {
    //     self.sideMenuOptions.push({ value: value.id, label: value.title });
    //   });
    // });
    self.editId = this.$route.params.id;
    axios.get(this.viewUrlApi(this.module, self.editId))
        .then((response) => {
          const responseData = response.data.data;
          self.plan.type = {
            id: responseData.type,
            label: responseData.type,
          };
          self.plan.industryId = {
            value: responseData.industryId,
            label: responseData.industryTitle,
          };
          self.plan.name = responseData.name;
          self.plan.description = responseData.description;
          self.plan.duration = {
            id: responseData.durationType,
            label: responseData.durationType,
          };
          self.plan.status = responseData.status;
          self.plan.no_user = responseData.noOfUsers;
          self.plan.no_branch = responseData.noOfBranch;
          self.plan.can_join = responseData.internationalGroup;
          self.plan.is_free = responseData.isFree;
          self.plan.is_auto_assign = responseData.isAutoAssign;
          // self.sideMenuList = responseData.sideMenuList;
          self.bottomMenuList = responseData.bottomMenuList;
          if (self.plan.is_free === 1 || self.plan.is_auto_assign === 1) {
            $('#plan_pricing').css('display', 'none');
          } else {
            $('#plan_pricing').css('display', 'block');
          }
          self.plan.no_of = responseData.duration;
          self.inputs = responseData.planPricing;
          if (self.inputs.length === 0) {
            self.inputs = [{"id": "country0", "price": "", "per_person_price": "", "countryId": ""}];
          }
          self.plan.featuredetail = responseData.features;
          store.commit("showLoader", false);
        })
        .catch(function (error) {
          store.commit("showLoader", false);
        });
  },
  watch: {
    $route: {
      immediate: true,
      handler(route) {
        if (route.query && route.query.page) {
          this.activePage = Number(route.query.page);
        }
      },
    },
  },
  methods: {
    changePermission(index, val) {
      let self = this;
      axios.get('plan/module/permission/' + val.value).then((response) => {
        self.bottomMenuList[index].permission = [];
        self.bottomMenuList[index].permissionList = [];
        response.data.data.map(function (value, key) {
          self.bottomMenuList[index].permissionList.push({value: value.id, label: value.title});
        });
      });
    },
    // changeSidePermission(index,val){
    //   let self = this;
    //   axios.get('plan/module/permission/'+val.value).then((response) => {
    //     self.sideMenuList[index].permission = [];
    //     self.sideMenuList[index].permissionList = [];
    //     response.data.data.map(function (value, key) {
    //       self.sideMenuList[index].permissionList.push({ value: value.id, label: value.title });
    //     });
    //   });
    // },
    addInput() {
      this.inputs.push({
        id: `country${++this.counter}`,
        price: '',
        per_person_price: '',
        countryId: '',
      });
    },
    subInput(id) {
      let self = this;
      const myArr = self.inputs;
      const index = myArr.findIndex(function (o) {
        return o.id === id;
      });
      if (index !== 0) myArr.splice(index, 1);
    },
    addMenu() {
      this.bottomMenuList.push({
        id: `${++this.menuCounter}`,
        selected: '',
        permission: [],
        permissionList: [],
      });
    },
    subMenu(id) {
      let self = this;
      const myArr = self.bottomMenuList;
      const index = myArr.findIndex(function (o) {
        return o.id === id;
      });
      if (index !== 0) myArr.splice(index, 1);
    },
    // addSideMenu() {
    //   this.sideMenuList.push({
    //     id: `${++this.sideMenuCounter}`,
    //     selected:'',
    //     permission:[],
    //     permissionList:[],
    //   });
    // },
    // subSideMenu(id) {
    //   let self = this;
    //   var myArr = self.sideMenuList;
    //   var index = myArr.findIndex(function(o){
    //     return o.id === id;
    //   });
    //   if (index !== 0) myArr.splice(index, 1);
    // },
    changedIsFree(e) {
      let self = this;
      this.v = e.target.checked;
      if (this.v) {
        self.plan.is_free = 1;
        self.plan.is_auto_assign = 0;
        $('#plan_pricing').css('display', 'none');
      } else {
        self.plan.is_free = 0;
        self.plan.is_auto_assign = 0;
        $('#plan_pricing').css('display', 'block');
      }
    },
    changedIsAutoAssign(e) {
      let self = this;
      this.v = e.target.checked;
      if (this.v) {
        self.plan.is_auto_assign = 1;
        self.plan.is_free = 0;
        $('#plan_pricing').css('display', 'none');
      } else {
        self.plan.is_auto_assign = 0;
        self.plan.is_free = 0;
        $('#plan_pricing').css('display', 'block');
      }
    },
    changedIsActive(e) {
      let self = this;
      this.v = e.target.checked;
      if (this.v) {
        self.plan.status = 1;
      } else {
        self.plan.status = 0;
      }
    },
    changedDuration(e) {
      let self = this;
      if (self) {
        alert(e);
      } else {
        alert(e);
      }
    },
    changedCanJoin(e) {
      let self = this;
      this.v = e.target.checked;
      if (this.v) {
        self.plan.can_join = 1;
      } else {
        self.plan.can_join = 0;
      }
    },
    onProfileSubmit() {
      let self = this;
      this.$v.plan.$touch();
      let paymentId = self.editId;
      if (this.$v.plan.$invalid) {
        self.submitted = false; //Enable Button
        this.submitStatus = "ERROR";
        console.log(this.$v.$invalid);
      } else {
        console.log('else');
        self.submitted = true; //Disable Button
        this.submitStatus = "PENDING";
        setTimeout(() => {
          const postData = {
            "bottomMenu": self.bottomMenuList,
            'noOfUsers': self.plan.no_user,
            'noOfBranch':self.plan.no_branch,
            'informToUser': self.plan.informToUser,
          }
          console.log(postData);
          axios
              .post(this.editUrlWeb(this.module, self.editId), postData)
              .then((response) => {
                self.submitted = false; //Enable Button
                store.commit("showLoader", false); // Loader Off
                if (response.data.code === "200") {
                  self.err_msg = response.data.message;
                  localStorage.setItem("notificationType", "success");
                  localStorage.setItem(
                      "notificationMessage",
                      response.data.message
                  );
                  self.messageColor = "success";
                  this.$router.push({path: `/profile/edit-plan-permissions/${paymentId}`});
                  self.dismissCountDown = 10;
                  window.scrollTo(0, 0);
                } else {
                  self.err_msg = response.data.message;
                  self.messageColor = "danger";
                  self.dismissCountDown = 10;
                }
              })
              .catch(function (error) {
                let data;
                if (error.response.data.error) {
                  data = error.response.data.error.toString();
                } else {
                  data = error.response.data.message;
                }
                self.err_msg = data;
                self.dismissCountDown = 10;
                self.submitted = false; //Enable Button
              });
        }, 500);
      }
    },
    setAlert(value) {
      this.showAlert = value;
    },
  },
};
</script>
<style>
#app {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  margin-top: 60px;
}
</style>